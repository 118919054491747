import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from 'vuex-persist';

Vue.use(Vuex);

// root state object.
// each Vuex instance is just a single state tree.
const state = {
    loading: false,
    token: "",
    terminal: {},
    error: false
}

// mutations are operations that actually mutate the state.
// each mutation handler gets the entire state tree as the
// first argument, followed by additional payload arguments.
// mutations must be synchronous and can be recorded by plugins
// for debugging purposes.
const mutations = {

    setLoading(state, value = true) {
        state.loading = value;
    },

    setToken(state, token) {
        state.token = token;
    },

    setTerminal(state, terminal = {}) {
        state.terminal = terminal;
    },

    setError(state, error = false) {
        state.error = error;
    }
}

const getters = {
    loading: state => state.loading,
    token: state => state.token,
    terminal: state => state.terminal,
    error: state => state.error || false,
}

const vuexLocal = new VuexPersist({
    storage: window.localStorage,
    reducer: state => ({
        token: state.token,
        terminal: state.terminal
    })
})


export default new Vuex.Store({
    state,
    getters,
    mutations,
    //actions,
    plugins: [vuexLocal.plugin]
});