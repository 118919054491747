import Vue from 'vue'
import App from './App.vue'

import router from "./router/index.js";
import store from "./store";

import "../node_modules/bulma/css/bulma.min.css"


import _axios from "axios"
import { cacheAdapterEnhancer } from 'axios-extensions';
const axios = _axios.create({
  baseURL: '/',
  headers: { 'Cache-Control': 'no-cache' },
  // disable the default cache and set the cache flag
  adapter: cacheAdapterEnhancer(_axios.defaults.adapter, { enabledByDefault: false, cacheFlag: 'useCache' })
});

axios.interceptors.request.use(req => {
  //`req` is the Axios request config, so you can modify
  //the `headers`.
  store.commit("setLoading");

  req.url = req.url.replace(/version/, `${process.env.VUE_APP_API_VERSION}`)
  //console.log(req.url)
  const token = store.getters.token;
  req.headers.authorization = `Bearer ${token}`;

  return req;
});
axios.interceptors.response.use(
  (response) => {
    //const now = Date.now();
    //console.info(`Api Call ${response.config.url} took ${now - response.config.config.start}ms`);
    store.commit("setLoading", false);
    //Snackbar.open(`positioned bottom-right with a green 'OK' button`)
    return response;
  },
  (error) => {
    switch (error.response.status) {
      case 401:
        router.push("/login");
        break;
      case 403:
        //router.push("/403");
        break;
      //case 404:
      //Snackbar.open("404! Arquivo não encontrado");
      //break;
      case 500:
        store.commit("setError", error.response.data);
        //router.push("/500");
        break;
    }
    store.commit("setLoading", false);
    return Promise.reject(error)
  },
);
Vue.prototype.$http = axios;

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
