<template>
  <router-view />
</template>
<script>
export default {
  name: "App",

  data() {
    return {};
  },
};
</script>
<style >
html,
body {
  height: 100% !important;
  background-color: #00d1b2 !important;
}
</style>


