import Vue from "vue"
import VueRouter from "vue-router"
import store from "./../store/index";



Vue.use(VueRouter)

const router = new VueRouter({
    base: '/',
    //mode: "history",
    routes: [

        //terminal
        {
            path: "/terminals",
            name: "Terminals",
            component: () => import("./../views/Terminals.vue"),
            //meta: { requiresAuth: true }
        },
        {
            path: "/terminal_ticket/:id",
            name: "Terminal",
            component: () => import("./../views/terminal/Ticket.vue"),
            //meta: { requiresAuth: true }
        },
        /*{
            path: "/terminal_call/:id",
            name: "Terminal",
            component: () => import("./../views/terminal/Call.vue"),
            //meta: { requiresAuth: true }
        },*/
        {
            path: "/terminal_call/:id",
            component: () => import("./../templates/terminalCall.vue"),
            children: [
                {
                    path: "/",
                    component: () => import("./../views/terminal/Call.vue"),
                    meta: { requiresAuth: true }
                }
            ]
        },

        //auth
        {
            path: "/",
            name: "login",
            component: () => import("./../views/auth/Login.vue"),
        },
        {
            path: "recover_password",
            //name: "auth.recover_password",
            component: () => import("./../views/auth/RecoverPassword.vue"),
        },
        {
            path: "reset_password/:token",
            //name: "auth.reset_password",
            component: () => import("./../views/auth/ResetPassword.vue"),
        },

    ]
})


router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.

        if (!store.getters.token) {
            next({ name: "auth/login" })
        } else {
            next() // go to wherever I'm going
        }
    } else {
        next() // does not require auth, make sure to always call next()!
    }
})



export default router